import React from 'react'
import Box from 'components/box'
import Paragraph from 'components/paragraph'
import Layout from 'components/layout'

const NotFound = () => (
    <Layout>
        <Box>
            <Paragraph fontSize={15} fontSizeMobile={10}>
                404
            </Paragraph>
            <Paragraph>We are sorry, the page you requested cannot be found.</Paragraph>
        </Box>
    </Layout>
)

export default NotFound
